import Rest from '@/services/Rest';

/**
 * @typedef {PermissionService}
 */
export default class PermissionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/permission'
}
