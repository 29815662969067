<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. permissões" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
 <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 pb-2">
                                                    <label>Permissões ao usuário </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="Procurar" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-2">
                                                        <div class="col-12 p-0">
                                                            <!-- <div class="col-12 pt-4 pb-1 px-3 font-b-7">Painel de controle</div> -->
                                                            <div class="col-12 col-md-10 col-lg-8 p-1">
                                                                <div class="col-12"> <b-form-checkbox-group
                                                                    v-model="user.permissions"
                                                                    :options="permissions">
                                                                    </b-form-checkbox-group>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="border-line-div"></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn btn-info ml-2">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">USUÁRIO</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">PERMISSÃO</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">ACESSOS</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in users" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                            <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <!-- <img src="../../assets/images/users/widget-table-pic1.jpg" alt="user" class="rounded-circle card-body-list-img" /> -->
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.company.name }}</h6>
                                                        <h6 class="text-truncate funcoes">{{ item.cnpj}}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate permissao"><span class="cod d-lg-none d-block">PERMISSÃO <br></span> Master</h6>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="acessos"><span class="cod d-lg-none d-block">ACESSOS <br></span><span v-for="obj in item.company.permissions" :key="obj.id">{{ obj.permission.name }}</span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <a class="btn btn-success text-white rounded-circle btn-circle font-20 card-body-list-cta" href="javascript:void(0);" @click="selectUser(item)">+</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <div class="row">
                <div class="col-12">
                    <BasePagination
                        v-if="users.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import QAdminService from "@/services/resources/QAdminService";
import PermissionService from "@/services/resources/PermissionService";
const servicePermission = PermissionService.build();
const service = QAdminService.build();

export default {
  data() {
    return {
      name: null,
      permissions: [],
      users: [],
      search: '',
      user:{
          id: null,
          permissions: []
      },
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0
      },
      pages:[
        {
            name: 'dashboard',
            to: 'Dashboard'
        },
        {
            name: 'permissões',
            to: 'Permission'
        }
      ],
      menu:[
        {
            name: 'Permissões',
            to: 'Permission'
        }
      ]
    };
  },
  methods: {
    selectUser(item){
        this.user.id = item.id;
        this.user.permissions = item.company.permissions.map(elem => elem.permission_id)
    },
    toPage(page) {
        this.pagination.currentPage = page;
        this.fetchUsers();
    },
    perPage(qtd) {
        this.pagination.currentPage = 1;
        this.pagination.perPage = qtd;

        this.fetchUsers();
    },
    save(){
        if(this.user.permissions.length == 0){
            this.$bvToast.toast('Você precisa selecionar pelo menos uma permissão!', {
                title: 'Atualizar permissão',
                autoHideDelay: 5000,
                type: 'danger'
            });
            return;
        }

        servicePermission
          .update(this.user)
            .then(resp => {
                this.$bvToast.toast('Permissão atualizada com sucesso!', {
                    title: 'Atualizar permissão',
                    autoHideDelay: 5000,
                    type: 'success'
                });
                this.user = {
                    id: null,
                    permissions: []
                }
                this.fetchUsers();
            })
            .catch(err => {
              console.log(err)
            })

    },
    fetchUsers(){
        
        let data = {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
        };

        if(this.search){
            data['search'] = this.search;
        }

        service
          .search(data)
            .then(resp => {
                this.users = resp.data;
                this.pagination.totalPages = resp.last_page;
                this.pagination.itens = resp.total;
            })
            .catch(err => {
              console.log(err)
            })
    },
    fetchPermission(){
        
        let data = {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
        };

        servicePermission
          .search(data)
            .then(resp => {
                this.permissions = resp.map(elem => {
                    return {
                        value: elem.id,
                        text: elem.name
                    }
                });
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
      this.fetchUsers();
      this.fetchPermission();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>